<template>
	<div
		:class="{
			'is-active': highlight,
			'is-not-achieved': !avatar.achieved,
		}"
		class="is-relative"
		@click="$emit('click', avatar)"
	>
		<avatar :avatar="getAvatarWithUrl(avatar.url)" :highlight="highlight" size="large" />
		<base-icon v-if="!avatar.achieved" :size="3" :class="lockBackground" icon="lock" class="item-lock" />
	</div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon.vue';
import Avatar from '@/components/ui/user/Avatar.vue';

export default {
	name: 'AchievableAvatar',
	components: { Avatar, BaseIcon },
	props: {
		avatar: {
			type: Object,
			required: true,
		},
		highlight: {
			type: Boolean,
			default: false,
		},
		backgroundColor: {
			type: String,
			default: '#008dff',
		},
		lockBackground: {
			type: String,
			default: 'has-background-white',
		},
	},
	computed: {
		avatar_url() {
			return this.avatar.url ?? null;
		},
	},
	methods: {
		getAvatarWithUrl() {
			return {
				colors: {
					background: this.backgroundColor,
				},
				url: this.avatar.url,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.is-not-achieved {
	.avatar {
		filter: grayscale(80%);
		opacity: 0.4;
	}
	.item-lock {
		position: absolute;
		left: 3.2rem;
		bottom: -0.5rem;
	}
}
</style>
