var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"tile is-ancestor"},[_c('div',{staticClass:"tile is-parent"},[_c('div',{staticClass:"tile is-child box is-rounded p-4 is-flex"},[_c('div',{staticClass:"box is-rounded no-box-shadow p-4 is-relative has-background-blue-lightest",staticStyle:{"width":"100%"}},[(_vm.schoolClasses.length === 0)?_c('div',{staticClass:"graph-no-classes"},[_vm._v(" Her kommer en lækker grafik over dine holds aktivitet. Start med at "),_c('router-link',{staticClass:"button is-ghost is-medium p-0",staticStyle:{"height":"auto"},attrs:{"to":{ name: 'school_classes' }}},[_vm._v(" oprette dine hold her")]),_vm._v(". ")],1):_vm._e(),_c('TeacherInsightsGraph')],1)])])])]),(_vm.hasLoaded)?_c('div',{staticClass:"column is-12 is-6-desktop"},[_c('div',{staticClass:"tile is-ancestor"},[_c('div',{staticClass:"tile is-parent max-width-full"},[_c('div',{staticClass:"tile is-child box is-rounded insight-tile max-width-full"},[_c('div',{staticClass:"insight-tile-header is-flex is-align-items-center p-5"},[_c('BaseIcon',{attrs:{"icon":"tilde-cutout","color":"green","size":5,"is-fill":""}}),_c('p',{staticClass:"title is-5 ml-3"},[_vm._v("Leaderboard")])],1),(_vm.topStudents.length > 0)?_c('div',{staticClass:"insight-tile-list"},_vm._l((_vm.topStudents),function(student,index){return _c('router-link',{key:index,staticClass:"px-5 py-3 columns is-mobile",attrs:{"to":{
									name: 'student_report',
									params: {
										user_id: student.user_id,
									},
								}}},[_c('div',{staticClass:"column is-1 p-0"},[_c('div',{staticClass:"place",class:[`place-${index + 1}`]},[_vm._v(" "+_vm._s(index + 1)+" ")])]),_c('div',{staticClass:"column is-2 py-0"},[_c('Avatar',{attrs:{"avatar":student.avatar}})],1),_c('div',{staticClass:"column is-6 student-name"},[_vm._v(" "+_vm._s(student.name)+" ")]),_c('div',{staticClass:"student-points column is-3 is-flex is-justify-content-space-between is-align-items-center py-0"},[_c('ExerciseIcon',{attrs:{"type":"points","color":"gold","no-padding":""}}),_c('span',[_vm._v(_vm._s(_vm.formatLargeNumber( student.points, )))])],1)])}),1):_c('div',{staticClass:"insight-tile-list"},[_vm._m(0)])])])])]):_vm._e(),(_vm.hasLoaded)?_c('div',{staticClass:"column is-12 is-6-desktop"},[_c('div',{staticClass:"tile is-ancestor"},[_c('div',{staticClass:"tile is-parent"},[_c('div',{staticClass:"tile is-child box is-rounded insight-tile"},[_c('div',{staticClass:"insight-tile-header is-flex is-align-items-center p-5"},[_c('BaseIcon',{attrs:{"icon":"flag-solid","color":"blue","size":5,"is-fill":""}}),_c('p',{staticClass:"title is-5 ml-3"},[_vm._v("Nyheder")])],1),_c('div',{staticClass:"insight-tile-list"},_vm._l((_vm.news),function(story,key){return _c('a',{key:key,staticClass:"insight-news-item p-5",attrs:{"href":story.url,"target":"_blank"}},[_c('div',{staticClass:"is-8 insight-date has-text-weight-normal"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(story.date, 'D. MMMM YYYY', ))+" ")]),_c('div',{staticClass:"insight-title is-6"},[_vm._v(" "+_vm._s(story.title)+" ")])])}),0)])])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-5 py-3 columns"},[_c('div',{staticClass:"column is-12"},[_vm._v(" Dine mest aktive elever kommer her. ")])])
}]

export { render, staticRenderFns }