/**
 *
 * @param {*} featuredAchievements
 * @returns null or Map of {key: featured_achievement_id, value: most progressed achievement for the key}
 *
 * Returns the most progressed achievement connected to each featured achivement
 * (there is one achievement connected for each language)
 * Only returns achievements that are connected to a featured achievement relation
 * Excludes outdated achievements of they are not achieved or only partially achieved
 */
export function featuredAchievementsDisplayed(featuredAchievements) {
	const now = new Date().setHours(0, 0, 0, 0);

	let featuredAchievementsDisplayed = new Map();

	featuredAchievements.forEach(achievement => {
		const far = achievement.featured_achievement_relation;

		// return nothing if there is no featured achievement relation connected
		if (!far) return;

		var achievedProgress = 0;
		const endDate = new Date(achievement.end_at);

		// check if achieved to any degree at all
		if (achievement.latest_achieved_at) {
			// calculate progress of current achievement
			achievedProgress =
				achievement.levels.filter(
					level =>
						level.user_achievement_level != null &&
						level.user_achievement_level.amount > 0,
				).length / achievement.levels.length;

			// only interested in displaying outdated achievements if they are fully achieved
			if (achievedProgress < 1 && endDate < now) return;
		} else {
			// same if not achieved at all
			if (endDate < now) return;
		}

		// check if this achievement is more progressed than currently stored achievement
		// connected to the featured achievement
		if (featuredAchievementsDisplayed.has(far.featured_achievement_id)) {
			const current = featuredAchievementsDisplayed.get(far.featured_achievement_id);
			if (current.achievedProgress === 1 || current.achievedProgress > achievedProgress) return;
		}

		// update to the currently most progressed achivement of the featured achievement
		const updatedAchievement = {
			item: achievement,
			achievedProgress: achievedProgress,
		};

		featuredAchievementsDisplayed.set(far.featured_achievement_id, updatedAchievement);
	});
	return featuredAchievementsDisplayed;
}

export function featuredAchievementsDisplayedAsArray(featuredAchievements) {
	const featuredAchievementsDisplayedMap = featuredAchievementsDisplayed(featuredAchievements);
	return Array.from(featuredAchievementsDisplayedMap.values()).map(a => a.item);
}
