<template>
	<svg class="" :style="colorStyles"><use :xlink:href="url"></use></svg>
</template>

<script>
export default {
	name: 'AvatarSvg',
	props: {
		avatar: {
			type: Object,
			required: true,
		},
	},
	computed: {
		colorStyles() {
			return {
				'--background': this.avatar.colors.background,
				'--skin': this.avatar.colors.skin,
				'--skin_neck': this.avatar.colors.skin_neck,
				'--facial_hair': this.avatar.colors.facial_hair,
				'--undercut': this.avatar.colors.undercut,
				'--hair': this.avatar.colors.hair,
				'--shirt': this.avatar.colors.shirt,
				'--accessory': this.avatar.colors.accessory,
				'--eyes': '#832745',
				'--mouth': '#ffffff',
				'--shirt_neck': '#382b3d',
			};
		},
		url() {
			return require('@/assets/images/avatars.svg') + `#avatar-${this.avatar.style}`;
		},
	},
};
</script>

<style lang="scss" scoped>
svg {
	width: 100%;
	aspect-ratio: 3 / 4;
}
</style>
