<template>
	<div>
		<BaseLoader v-if="loading" size="large" class="mt-6" />

		<template v-else>
			<HomeworkTabs
				v-if="hasHomework || isTeacher || incompleteActivityCount > 0"
				:homework-tabs="homeworkTabs"
				:completed-activities="completedActivities"
			/>

			<div v-if="activeFeaturedAchievements.length > 0" style="padding-bottom: 30px">
				<FeaturedAchievements :achievements="activeFeaturedAchievements" :courses="courses" />
			</div>

			<div v-if="isTeacher" style="padding-bottom: 30px">
				<div class="is-flex is-align-items-center py-4 mt-5 mb-5 underlined-title">
					<BaseIcon
						icon="plus"
						class="create-icon has-background-green"
						color="white"
						show-background
						has-transition
					/>
					<div class="title is-5 ml-3">Genveje</div>
				</div>
				<Shortcuts />
			</div>

			<div v-if="isTeacher" style="padding-bottom: 30px">
				<div class="is-flex is-align-items-center py-4 mt-5 mb-4 underlined-title">
					<BaseIcon icon="keyhole-cutout" color="purple" no-padding is-fill />
					<div class="title is-5 ml-3">Indsigt</div>
				</div>
				<div class="columns">
					<div class="column mt-7 is-flex-grow-0 is-flex-shrink-0 mb-4">
						<ProfileWithStats style="width: 275px" />
					</div>
					<div class="column mt-7">
						<TeacherInsights />
					</div>
				</div>
			</div>

			<div v-if="isStudent">
				<div
					v-if="hasHomework || incompleteActivityCount > 0"
					class="is-flex is-align-items-center pt-4 mt-5 pb-4 mb-5 underlined-title"
				></div>
				<div class="columns">
					<div class="column is-3 mt-7 mb-4">
						<ProfileWithStats />
					</div>
					<div class="column is-9 mt-7">
						<LatestAchievements :achievements="achievementsDisplayed" />
						<UnfinishedExercises />
					</div>
				</div>
			</div>
			<div v-else-if="achievementsDisplayed.length > 0">
				<div class="is-flex is-align-items-center py-4 mt-5 mb-4 underlined-title">
					<BaseIcon icon="hexagon-star" color="yellow-dark" no-padding is-fill />
					<div class="title is-5 ml-3">Bedrifter</div>
				</div>

				<div class="columns is-multiline is-variable pt-4">
					<div
						v-for="(achievement, index) in achievementsDisplayed"
						:key="index"
						class="column is-3-mobile is-4-tablet is-3-desktop"
					>
						<AchievementCard :achievement="achievement" />
					</div>
				</div>
				<div class="is-flex is-justify-content-center pt-2">
					<router-link :to="{ name: 'profile' }" class="change-avatar-button">
						<div class="button is-medium is-fullwidth">Se alle bedrifter</div>
					</router-link>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import AchievementCard from '@/components/dashboard/AchievementCard';
import Shortcuts from '@/components/dashboard/Shortcuts';
import BaseIcon from '@/components/base/BaseIcon';
import BaseLoader from '@/components/base/BaseLoader';
import HomeworkTabs from '@/components/dashboard/HomeworkTabs';
import ProfileWithStats from '@/components/user/ProfileWithStats';
import LatestAchievements from '@/components/dashboard/LatestAchievements.vue';
import FeaturedAchievements from '@/components/dashboard/FeaturedAchievements.vue';
import TeacherInsights from '@/components/dashboard/TeacherInsights.vue';
import UnfinishedExercises from '@/components/dashboard/UnfinishedExercises.vue';
import { featuredAchievementsDisplayed } from '@/utils/featuredAchievements.js';
import { mapGetters } from 'vuex';

export default {
	name: 'DashboardView',
	components: {
		AchievementCard,
		Shortcuts,
		BaseIcon,
		BaseLoader,
		FeaturedAchievements,
		HomeworkTabs,
		LatestAchievements,
		ProfileWithStats,
		TeacherInsights,
		UnfinishedExercises,
	},
	data() {
		return {
			loading: false,
			courses: [],
			hasHomework: false,
			student: null,
			homeworkTabs: [],
			completedActivities: [],
		};
	},
	computed: {
		...mapGetters('user', [
			'user',
			'isTeacher',
			'isStudent',
			'achievements',
			'featuredAchievements',
			'school',
		]),
		achievementsDisplayed() {
			let achievementsToShow = this.isTeacher
				? [...this.achievements.filter(a => a.levels.some(l => l.only_for === 'teachers'))]
				: [...this.achievements];

			if (!this.featuredAchievements || this.isTeacher) return achievementsToShow;

			let featuredAchievementsToDisplay = featuredAchievementsDisplayed(this.featuredAchievements);
			featuredAchievementsToDisplay.forEach(item => achievementsToShow.push(item.item));

			return achievementsToShow;
		},
		activeFeaturedAchievements() {
			const now = new Date().setHours(0, 0, 0, 0);
			return this.featuredAchievements
				? this.featuredAchievements.filter(item => new Date(item.end_at) > now)
				: [];
		},
		incompleteActivityCount() {
			let count = 0;
			// eslint-disable-next-line no-unused-vars
			for (const [key, value] of Object.entries(this.completedActivities)) {
				if (!value) {
					count++;
				}
			}
			return count;
		},
	},
	created() {
		this.loading = true;
		const requests = [
			this.loadDashboardData(),
			this.loadAchievements(),
			this.loadFeaturedAchievements(),
			this.loadCourses(),
		];
		Promise.all(requests).finally(() => (this.loading = false));
	},
	methods: {
		featuredAchievementsDisplayed,
		loadAchievements() {
			return this.$store.dispatch('user/getAchievements');
		},
		loadFeaturedAchievements() {
			const educations_id = this.school.school_type.id == 2 ? 1 : 10;
			return this.$store.dispatch('user/getFeaturedAchievements', { educations_id });
		},
		loadCourses() {
			return this.$store.dispatch('courses/getSchoolCourses', this.school.id).then(courses => {
				this.courses = courses;
			});
		},
		loadDashboardData() {
			return this.$store.dispatch('user/getDashboard').then(response => {
				this.hasHomework = response.has_any_homework;
				this.homeworkTabs = response.homework_tabs;
				this.completedActivities = response.completed_activities ?? ['welcome', 'create-class'];
				this.news = response.news;
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.underlined-title {
	border-bottom: 4px solid $grey-lighter;
}
</style>
