var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"is-clickable",on:{"click":_vm.showPopup}},[_c('div',{staticClass:"is-flex is-flex-direction-column is-align-items-center has-text-centered"},[_c('div',{staticClass:"pb-5"},[_c('div',{staticClass:"is-relative"},[_c('img',{class:{ 'is-incomplete': _vm.achievedLevels === 0 },attrs:{"src":_vm.achievement.badge_image_url,"width":"130","alt":"achievement badge"}}),_vm._l((_vm.achievement.levels.length),function(starIndex){return _c('div',{key:starIndex,style:(_vm.starStyle(starIndex, 3))},[_c('BaseIcon',{attrs:{"color":_vm.achievedLevels >= starIndex
									? 'yellow-dark'
									: 'grey-light',"icon":"star","size":3,"no-padding":""}})],1)})],2)]),_c('div',{staticClass:"progress-wrapper mx-auto"},[(_vm.progress < 100)?_c('BaseProgressBar',{staticClass:"progressbar my-4",attrs:{"progress":_vm.progress,"color-name":_vm.achievement.color,"height":"small"}}):_vm._e(),(_vm.progress === 100)?_c('BaseCheckbox',{staticClass:"my-1",attrs:{"active-color":_vm.achievement.color,"selected":true,"rounded":true}}):_vm._e()],1),_c('div',{staticClass:"is-size-6 is-family-secondary"},[_vm._v(_vm._s(_vm.achievement.title))]),_c('div',{staticClass:"is-size-8"},[_vm._v(_vm._s(_vm.achievement.description))])])]),_c('SplitModal',{ref:"achievementModal",attrs:{"image-width":4,"left-column-classes":[
			'has-background-grey-lighterer',
			'has-text-centered',
			'has-fixed-img',
			'is-flex',
			'is-flex-direction-column',
			'is-justify-content-space-between',
		],"footer-column-classes":['has-background-white'],"narrow-body-padding":"","reduce-top-margin":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.hasBeenShown && _vm.achievement.graphic)?_c('img',{staticClass:"achievement-graphic",attrs:{"src":_vm.achievement.graphic_url,"alt":"achievement graphics"}}):_vm._e()]},proxy:true},{key:"img",fn:function(){return [_c('div',{staticClass:"mb-3 mt-4 mx-auto pb-6",staticStyle:{"width":"150px"}},[_c('div',{staticClass:"is-relative"},[_c('img',{class:{ 'is-incomplete': _vm.achievedLevels === 0 },attrs:{"src":_vm.achievement.badge_image_url,"alt":"achievement badge"}}),_vm._l((_vm.achievement.levels.length),function(starIndex){return _c('div',{key:starIndex,style:(_vm.starStyle(starIndex, 6))},[_c('BaseIcon',{attrs:{"color":_vm.achievedLevels >= starIndex
									? 'yellow'
									: 'grey-light',"icon":"star","size":6,"no-padding":""}})],1)})],2)]),(_vm.showAchievedPercentage)?[_c('div',{staticClass:"delimiter"}),_c('div',{staticClass:"mb-6 mt-2 mx-6",class:{ 'is-incomplete': _vm.achievedLevels === 0 }},[_c('BaseIcon',{staticClass:"mb-3 mx-auto",attrs:{"icon":_vm.achievedPercentageIcon,"size":12,"no-padding":""}}),_c('div',{staticClass:"is-size-6 has-text-grey-dark",class:[_vm.achievedPercentage < 1 ? 'px-3' : 'px-5']},[_vm._v(" "+_vm._s(_vm.achievedPercentageText)+" ")])],1)]:_vm._e()]},proxy:true},{key:"body",fn:function(){return [_c('p',{staticClass:"title is-3 mb-1"},[_vm._v(_vm._s(_vm.achievement.title))]),_c('p',{staticClass:"mb-5 is-flex is-align-items-center"},[_vm._v(" "+_vm._s(_vm.achievement.description)+" "),(_vm.difficulty)?_c('ExerciseIcon',{staticClass:"ml-2",attrs:{"type":_vm.exerciseIcon}}):_vm._e()],1),_c('div',{staticClass:"mb-5"},_vm._l((_vm.achievement.levels),function(level,levelIndex){return _c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
						content: _vm.levelTooltip(level),
						placement: 'bottom',
						onShow: () => _vm.showAchievedPercentage,
					}),expression:"{\n\t\t\t\t\t\tcontent: levelTooltip(level),\n\t\t\t\t\t\tplacement: 'bottom',\n\t\t\t\t\t\tonShow: () => showAchievedPercentage,\n\t\t\t\t\t}"}],key:level.id,staticClass:"is-flex py-1"},[_c('div',{staticClass:"mr-4"},[_c('div',{staticClass:"is-flex"},_vm._l((_vm.achievement.levels.length),function(starIndex){return _c('BaseIcon',{key:starIndex,attrs:{"color":levelIndex + 1 >= starIndex
										? 'yellow'
										: 'grey',"icon":"star","size":3,"no-padding":""}})}),1)]),_c('div',{staticClass:"is-relative",staticStyle:{"width":"100%"}},[(level.type === 'click_link')?_c('a',{class:{ 'completed-step': _vm.isLevelCompleted(level) },attrs:{"href":level.string_value,"target":"_blank"},on:{"click":function($event){return _vm.clickedLink(level.string_value)}}},[_vm._v(" "+_vm._s(_vm.getLevelText(level))+" ")]):(level.type === 'complete_exercise')?_c('a',{class:{ 'completed-step': _vm.isLevelCompleted(level) },on:{"click":function($event){$event.preventDefault();return _vm.openExerciseLink(level)}}},[_vm._v(" "+_vm._s(_vm.getLevelText(level))+" ")]):_c('span',{class:{ 'completed-step': _vm.isLevelCompleted(level) }},[_vm._v(" "+_vm._s(_vm.getLevelText(level))+" ")]),_c('div',{staticStyle:{"position":"absolute","width":"60%"}},[_c('BaseProgressBar',{staticClass:"progressbar",attrs:{"progress":_vm.getLevelProgress(level),"color-name":_vm.achievement.color,"height":"mini"}})],1)])])}),0),(_vm.showAchievedPercentage && _vm.achievement.avatar)?_c('div',{staticClass:"is-flex"},[_c('div',{staticClass:"mr-4"},[_c('div',{staticClass:"px-4 py-3 has-border has-border-blue has-background-blue-lightest avatar-wrapper"},[_c('achievable-avatar',{attrs:{"avatar":_vm.achievableAvatar,"background-color":_vm.achievableAvatarColor,"lock-background":"has-background-blue-lightest"}})],1)]),_c('div',{staticClass:"is-flex is-flex-direction-column is-justify-content-center"},[_c('div',{staticClass:"is-bold is-4 mb-1"},[_vm._v(_vm._s(_vm.achievement.avatar_title))]),_c('div',[_vm._v(_vm._s(_vm.achievement.avatar_description))])])]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"has-text-right"},[_c('button',{staticClass:"button is-medium is-primary",attrs:{"type":"button"},on:{"click":_vm.hidePopup}},[_vm._v(" Luk ")])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }