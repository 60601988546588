<template>
	<div class="impersonation-icon" @mouseenter="isHovered = true" @mouseleave="isHovered = false">
		<svg
			:width="width"
			:height="height"
			:view-Box="`0 0 ${width} ${height}`"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<IconFrame :is-hovered="isHovered" :is-active="isActive">
				<IconAvatar v-if="!user" />
			</IconFrame>
		</svg>
		<div v-if="user" class="impersonation-icon-avatar">
			<Avatar :avatar="user.avatar" size="xsmall" />
		</div>
	</div>
</template>

<script>
import IconFrame from './IconFrame';
import IconAvatar from './IconAvatar';
import Avatar from '@/components/ui/user/Avatar';

export default {
	name: 'StudentImpersonationIcon',
	components: {
		IconFrame,
		IconAvatar,
		Avatar,
	},
	props: {
		width: {
			type: Number,
			default: 42,
		},
		height: {
			type: Number,
			default: 42,
		},
		user: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			isHovered: false,
		};
	},
	computed: {
		isActive() {
			return this.isHovered || !!this.user;
		},
	},
};
</script>

<style lang="scss" scoped>
.impersonation-icon {
	position: relative;

	.impersonation-icon-avatar {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding-bottom: 3px;
	}
}
</style>
