<script>
import PusherService from '@/libs/PusherService';
import { mapGetters } from 'vuex';

export default {
	name: 'AppUpdate',
	data() {
		return {
			// Global Pusher channel.
			globalChannel: null,
		};
	},
	computed: {
		...mapGetters('user', ['user']),
	},
	watch: {
		user(user) {
			if (user) {
				this.subscribeToGlobalChannel();
			} else {
				this.unsubscribeFromGlobalChannel();
			}
		},
	},
	created() {
		let refreshing = false;
		let initialInstall = false;

		if (!navigator.serviceWorker) {
			return;
		}

		onReady(() => {
			// Check current service worker registration
			navigator.serviceWorker.getRegistration().then(registration => {
				if (!registration || !registration.active) {
					initialInstall = true;
					return;
				}

				// Make sure any waiting service worker registration gets applied immediately.
				if (registration.waiting) {
					registration.waiting.postMessage('skipWaiting');
				}
			});

			// Refresh all open app tabs when a new service worker is installed.
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				if (initialInstall) {
					initialInstall = false;
					return;
				}
				if (refreshing) {
					return;
				}
				refreshing = true;
				Promise.all([
					this.$store.dispatch('app/clearConfigCache'),
					this.$store.dispatch('app/clearUniloginConfigCache'),
					this.$store.dispatch('lix/clearToken'),
				]).finally(() => {
					window.location.reload();
				});
			});

			if (this.user) {
				this.subscribeToGlobalChannel();
			}
		});
	},
	beforeDestroy() {
		this.unsubscribeFromGlobalChannel();
	},
	methods: {
		subscribeToGlobalChannel() {
			if (this.globalChannel) {
				return;
			}

			this.globalChannel = PusherService.subscribe('private-ml-global');

			this.globalChannel.bind('App\\Events\\FrontendUpdated', () => {
				document.dispatchEvent(new CustomEvent('FrontendUpdated'));
			});
		},
		unsubscribeFromGlobalChannel() {
			if (this.globalChannel) {
				this.globalChannel.unsubscribe();
				this.globalChannel = null;
			}
		},
	},
	render() {
		return null;
	},
};

function onReady(handler) {
	if (document.readyState === 'complete') {
		handler();
		return;
	}
	window.addEventListener('load', handler, { once: true });
}
</script>
