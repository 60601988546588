<template>
	<div v-if="user" class="has-dropdown user-menu" :class="{ 'is-active': open }">
		<a class="navbar-link is-arrowless" @click="$emit('toggle')">
			<div class="is-flex is-align-items-center">
				<Avatar :avatar="user.avatar" :has-dot="hasUnreadNotifications" />
				<div class="user-info">
					<div class="user-name has-text-grey-dark" v-text="userFirstName" />
					<div
						v-if="school"
						class="user-school is-size-8 has-text-grey"
						v-text="school.name"
					/>
				</div>
				<BaseChevron class="ml-4" :direction="open ? 'up' : 'down'" />
			</div>
		</a>
		<div v-if="open" class="navbar-dropdown is-boxed is-right p-0">
			<div v-if="showNotifications">
				<BaseGoBack
					text="Notifikationer"
					:text-size="7"
					text-color="grey-dark"
					hover-color="black"
					class="px-4 py-0"
					@click.native="closeNotifications"
				/>
				<Notifications
					:notifications="userNotifications"
					class="pb-4"
					@clicked-notification="close"
				/>
			</div>
			<div v-else class="p-4">
				<div
					class="navbar-item is-clickable has-hover-text-blue is-flex is-align-items-center is-justify-content-space-between pr-2"
					@click="showNotifications = true"
				>
					<p>Notifikationer</p>
					<div class="is-flex is-align-items-center">
						<BaseTag
							v-if="hasUnreadNotifications"
							type="danger"
							size="normal"
							class="mr-3"
						>
							{{ unreadUserNotifications.length }}
						</BaseTag>
						<BaseChevron direction="right" />
					</div>
				</div>
				<router-link
					v-if="isTeacher"
					class="navbar-item"
					:to="{ name: 'school_classes' }"
					@click.native="close"
					>Administration af hold
				</router-link>
				<router-link class="navbar-item" :to="{ name: 'profile' }" @click.native="close"
					>Profil</router-link
				>
				<a
					target="_blank"
					href="https://support.minlaering.dk"
					class="navbar-item"
					@click="close"
					>Hjælp</a
				>
				<hr class="navbar-divider" />
				<router-link class="navbar-item" :to="{ name: 'switch-school' }" @click.native="close"
					>Skift skole</router-link
				>
				<a class="navbar-item" @click="logout">Log ud</a>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Avatar from '@/components/ui/user/Avatar.vue';
import BaseChevron from '@/components/base/BaseChevron';
import BaseTag from '@/components/base/BaseTag';
import BaseGoBack from '@/components/base/BaseGoBack';
import Notifications from '@/components/notifications/Notifications';

export default {
	name: 'UserMenu',
	components: {
		Avatar,
		BaseChevron,
		BaseTag,
		BaseGoBack,
		Notifications,
	},
	props: {
		open: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showNotifications: false,
		};
	},
	computed: {
		...mapGetters('user', ['user', 'isTeacher', 'school']),
		...mapGetters('auth', ['uniloginLogoutUrl']),
		...mapGetters('notification', ['userNotifications', 'unreadUserNotifications']),
		userFirstName() {
			if (!this.user) {
				return '';
			}
			return this.user.name.split(' ')[0];
		},
		avatar() {
			return this.user.avatar;
		},
		hasUnreadNotifications() {
			return this.unreadUserNotifications.length > 0;
		},
	},
	watch: {
		open(isOpen) {
			if (!isOpen) {
				this.closeNotifications();
			}
		},
	},
	created() {
		// Get notifications on load.
		this.loadNotifications();

		// ensure that the user is fetched after deploy of new avatar builder
		// can be deleted after 2025-05-01
		if (this.user && !this.user.avatar?.colors?.background) {
			this.$store.dispatch('user/getUser');
		}
	},
	methods: {
		close() {
			this.$emit('close');
		},
		logout() {
			this.$store.dispatch('auth/logout').finally(() => {
				if (this.uniloginLogoutUrl) {
					window.location.href = this.uniloginLogoutUrl;
					return;
				}
				this.$router.push({ name: 'login' });
			});
		},
		closeNotifications() {
			if (this.showNotifications && this.hasUnreadNotifications) {
				this.markAllNotificationsAsRead();
			}
			this.showNotifications = false;
		},
		loadNotifications() {
			return this.$store.dispatch('notification/getNotifications');
		},
		markAllNotificationsAsRead() {
			return this.$store.dispatch('notification/markAllAsRead');
		},
	},
};
</script>
