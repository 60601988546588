<template>
	<div>
		<div class="columns is-multiline">
			<div class="column is-12">
				<div class="tile is-ancestor">
					<div class="tile is-parent">
						<div class="tile is-child box is-rounded p-4 is-flex">
							<div
								class="box is-rounded no-box-shadow p-4 is-relative has-background-blue-lightest"
								style="width: 100%"
							>
								<div
									v-if="schoolClasses.length === 0"
									class="graph-no-classes"
								>
									Her kommer en lækker grafik over dine holds
									aktivitet. Start med at
									<router-link
										:to="{ name: 'school_classes' }"
										class="button is-ghost is-medium p-0"
										style="height: auto"
									>
										oprette dine hold her</router-link
									>.
								</div>
								<TeacherInsightsGraph />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="hasLoaded" class="column is-12 is-6-desktop">
				<div class="tile is-ancestor">
					<div class="tile is-parent max-width-full">
						<div class="tile is-child box is-rounded insight-tile max-width-full">
							<div
								class="insight-tile-header is-flex is-align-items-center p-5"
							>
								<BaseIcon
									icon="tilde-cutout"
									color="green"
									:size="5"
									is-fill
								/>
								<p class="title is-5 ml-3">Leaderboard</p>
							</div>
							<div v-if="topStudents.length > 0" class="insight-tile-list">
								<router-link
									v-for="(student, index) in topStudents"
									:key="index"
									class="px-5 py-3 columns is-mobile"
									:to="{
										name: 'student_report',
										params: {
											user_id: student.user_id,
										},
									}"
								>
									<div class="column is-1 p-0">
										<div
											:class="[`place-${index + 1}`]"
											class="place"
										>
											{{ index + 1 }}
										</div>
									</div>
									<div class="column is-2 py-0">
										<Avatar :avatar="student.avatar" />
									</div>
									<div class="column is-6 student-name">
										{{ student.name }}
									</div>
									<div
										class="student-points column is-3 is-flex is-justify-content-space-between is-align-items-center py-0"
									>
										<ExerciseIcon
											type="points"
											color="gold"
											no-padding
										/>
										<span>{{
											formatLargeNumber(
												student.points,
											)
										}}</span>
									</div>
								</router-link>
							</div>
							<div v-else class="insight-tile-list">
								<div class="px-5 py-3 columns">
									<div class="column is-12">
										Dine mest aktive elever kommer her.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="hasLoaded" class="column is-12 is-6-desktop">
				<div class="tile is-ancestor">
					<div class="tile is-parent">
						<div class="tile is-child box is-rounded insight-tile">
							<div
								class="insight-tile-header is-flex is-align-items-center p-5"
							>
								<BaseIcon
									icon="flag-solid"
									color="blue"
									:size="5"
									is-fill
								/>
								<p class="title is-5 ml-3">Nyheder</p>
							</div>
							<div class="insight-tile-list">
								<a
									v-for="(story, key) in news"
									:key="key"
									:href="story.url"
									class="insight-news-item p-5"
									target="_blank"
								>
									<div
										class="is-8 insight-date has-text-weight-normal"
									>
										{{
											story.date
												| dateFormat(
													'D. MMMM YYYY',
												)
										}}
									</div>
									<div class="insight-title is-6">
										{{ story.title }}
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Avatar from '@/components/ui/user/Avatar.vue';
import BaseIcon from '@/components/base/BaseIcon';
import ExerciseIcon from '@/components/exercises/ExerciseIcon';
import TeacherInsightsGraph from '@/components/dashboard/TeacherInsightsGraph.vue';
import { mapGetters } from 'vuex';
import date from '@/mixins/filters/date';
import { formatLargeNumber } from '@/utils/math';

export default {
	name: 'TeacherInsights',
	components: { Avatar, BaseIcon, ExerciseIcon, TeacherInsightsGraph },
	mixins: [date],
	data() {
		return {
			hasLoaded: false,
			topStudentIds: [],
			news: [],
		};
	},
	computed: {
		...mapGetters('school', ['schoolClasses']),
		topStudents() {
			return this.topStudentIds
				.map(s => {
					const student = this.getStudentFromId(s.user_id);
					if (!student) {
						return null;
					}
					return {
						user_id: s.user_id,
						name: student.name,
						avatar: student.avatar,
						color: student.color,
						points: s.points,
					};
				})
				.filter(n => n);
		},
	},
	created() {
		setTimeout(() => {
			this.loadTopStudentsAndNews();
		}, 1000);
	},
	methods: {
		loadTopStudentsAndNews() {
			return this.$store.dispatch('user/getInsightItems').then(data => {
				this.topStudentIds = data.top_students;
				this.news = data.news;
				this.hasLoaded = true;
			});
		},
		getStudentFromId(id) {
			return this.schoolClasses
				.map(c => c.students)
				.flat()
				.find(s => s.id === id);
		},
		formatLargeNumber,
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.max-width-full {
	max-width: 100%;
}
.tile {
	&.insight-tile {
		padding: 0;
		.insight-tile-list {
			font-weight: bold;
			font-family: $family-secondary;
			> .columns {
				height: 80px;
				margin: 0;
				transition: color 250ms linear;
				color: $black;
				.place {
					border-radius: 0.85rem;
					width: 2rem;
					height: 2rem;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 10px;
					color: $grey;
					background-color: transparent;
				}
				.place-1 {
					color: white;
					background-color: $gold;
				}
				.place-2 {
					color: white;
					background-color: $silver;
				}
				.place-3 {
					color: white;
					background-color: $bronze;
				}
				.student-name {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				&:hover .student-name {
					color: $blue;
				}
			}
			> .insight-news-item {
				height: 100px;
				display: block;
				transition: color 250ms linear;
				border-top: 1px solid $grey-lighter;

				.insight-date {
					color: $grey;
					font-weight: normal;
					font-family: $family-primary;
				}
				.insight-title {
					color: $black;
				}
				&:visited {
					.insight-date {
						color: $grey-light;
					}
					.insight-title {
						color: $grey;
					}
				}

				&:hover {
					.insight-title {
						color: $blue;
					}
				}
			}
		}
	}
}

.graph-no-classes {
	position: absolute;
	background: rgba($white, 0.7);
	border-radius: $radius-larger;
	padding: 15rem;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	font-weight: bold;
	font-family: $family-secondary;
}
</style>
